/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.mat-bottom-sheet-container {
    min-height: 30vh;
    min-width: 100vw;
    width: 50vw;
    margin-top: 80px;
  }